import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'
import { ShareResourceInterface } from 'modules/share/types/share-interface'
import { getResourceApiSlug } from 'modules/share/utils/get-resource-api-slug'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { ErrorResponseInterface } from 'shared/types/error-response-content-interface'
import useSWR from 'swr'

export const useShareResource = ({
  hash,
  type,
  badRequestHandler,
}: {
  hash?: string
  type?: ShareResourceEnum
  badRequestHandler: (e: ErrorResponseInterface) => void
}) => {
  const { user } = useUser()
  const resourceApiSlug = getResourceApiSlug(type)
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ShareResourceInterface
  >({ method: RequestMethodsEnum.get, badRequestHandler })
  return useSWR(() => {
    if (!hash || !resourceApiSlug || !user) return
    switch (resourceApiSlug) {
      case 'share-funnel':
        return `/api/funnel/share-background/${hash}`
      case 'share-blog':
        return `/api/blog/share-background/${hash}`
      //// Migrating enum
      case 'funnel':
        return `/api/funnel/share-background/${hash}`
      case 'blog':
        return `/api/blog/share-background/${hash}`
      default:
        return `/api/${resourceApiSlug}/share/${hash}`
    }
  }, fetcher)
}
