import { toast } from '@systemeio/ui-shared'
import { ShareLoco } from 'modules/share/constants/share-loco'
import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'
import { useShareResource } from 'modules/share/hooks/use-share-resource'
import { getSuccessMessage } from 'modules/share/utils/get-success-message'
import { NextPage } from 'next'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Loader } from 'shared/components/loader'
import { SectionMessage } from 'shared/components/section-message'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { ErrorResponseInterface } from 'shared/types/error-response-content-interface'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'

const Share: NextPage = () => {
  const { query } = useRouter()
  const { t } = useLocoTranslation()
  const { hash, type } = query as { hash?: string; type?: ShareResourceEnum }
  const [error, setError] = useState('')
  const badRequestHandler = (e: ErrorResponseInterface) => {
    if (e.errors.common) {
      setError(e.errors.common.join(', '))
    }
  }

  const { data } = useShareResource({ hash, type, badRequestHandler })
  useEffect(() => {
    if (data) {
      toast.success(getSuccessMessage(t, type))
      Router.replace(data.redirect_url)
    }
  }, [data, type])

  return (
    // for now this page is used as a redirect page only
    <div className={'flex justify-center'}>
      {error ? (
        <SectionMessage>
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </SectionMessage>
      ) : (
        <Loader />
      )}
    </div>
  )
}
export const getServerSideProps = getBaseServerSideProps(ShareLoco)

export default Share
